import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { HeadingSection, SubtitleSection } from '../../../styles/Typography';
import { SvgWaveTop } from '../../../vectors/Waves';

const WrapperControl = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -90px;
  flex-direction: column;

  @media screen and (max-width: 350px) {
    & svg {
      height: 30px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gap-2) var(--padding);
  background: ${({ hasPaperBackground }) =>
    hasPaperBackground === false ? 'var(--gold-2)' : 'var(--blue-2)'};

  @media screen and (max-width: 640px) {
    overflow: hidden;
  }
`;

const Container = styled.div`
  width: var(--layout-desktop-container);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--gap-2);
  align-items: center;
  justify-items: center;

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
    row-gap: var(--gap-4);
  }
`;

const RoundedImage = styled(GatsbyImage)`
  border-radius: 100vw;
  max-width: 500px;
  max-height: 500px;
  height: min-content;

  & img {
    border-radius: 100vw;
  }

  @media screen and (max-width: 640px) {
    max-height: unset;
    max-width: unset;
    width: 110vw;
    height: 110vw;
  }
`;

const TextContent = styled.div`
  display: grid;
  row-gap: var(--gap-6);
  height: min-content;

  @media screen and (max-width: 640px) {
    width: calc(100vw - 50px);
  }
`;

const IllustrationWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const Illustration = styled.img`
  max-width: 300px;
`;

export const SectionWaveIllustration = ({
  title,
  description,
  roundedImageData,
  subtitle,
  illustrationUrl,
  hasPaperBackground,
}) => (
  <WrapperControl>
    <SvgWaveTop hasPaperBackground={hasPaperBackground} />
    <Wrapper hasPaperBackground={hasPaperBackground}>
      <Container>
        <RoundedImage
          image={roundedImageData}
          alt={title}
          description={description}
        />
        <TextContent>
          <HeadingSection goldHeading={hasPaperBackground || false}>
            {title}
          </HeadingSection>
          <SubtitleSection gold mobileSmaller>
            {subtitle}
          </SubtitleSection>
          {!illustrationUrl ? null : (
            <IllustrationWrapper>
              <Illustration
                width="300"
                height="300"
                src={illustrationUrl.illustrationUrl}
                alt={title}
              />
            </IllustrationWrapper>
          )}
        </TextContent>
      </Container>
    </Wrapper>
  </WrapperControl>
);
