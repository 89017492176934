/* eslint-disable import/no-unresolved */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIntl } from 'react-intl';
import { HeadingSection, RegularParagraph } from '../../../styles/Typography';
import { NavigatorButtonNormal } from '../../../ui/Buttons';
import 'swiper/css/bundle';
import { ForksDrawing } from '../../../vectors/Drawings';

SwiperCore.use([Pagination]);

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gap-2) var(--padding);
  background: white;
  overflow: hidden;
`;

const Container = styled.div`
  width: var(--layout-desktop-container);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    margin-bottom: var(--gap-2);
  }

  @media screen and (max-width: 1320px) {
    width: 100%;
  }
`;

const GridContainerLayout = styled.div`
  display: flex;
  width: var(--layout-desktop-container);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-4);
  margin-bottom: 100px;

  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 960px) {
    margin-bottom: var(--gap-4);
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

const GridContainer = styled.div`
  display: flex;
  width: 65%;

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

const ProductCard = styled.article`
  display: grid;
  row-gap: var(--gap-5);
  justify-items: left;
  height: min-content;

  & > h1 {
    width: 100%;
  }

  @media screen and (max-width: 860px) {
    padding-bottom: var(--gap-4);
  }
`;

const QuoteContainer = styled.div`
  display: grid;
  row-gap: var(--gap-6);
  height: min-content;
`;

const ProductImage = styled(GatsbyImage)`
  max-width: 300px;
`;

const Quote = styled.div`
  border-radius: 100vw;
  background: ${({ hasPaperBackground }) =>
    hasPaperBackground === false ? 'var(--gold-2)' : 'var(--blue-2)'};
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;

  @media screen and (max-width: 1000px) {
    padding: 60px;
  }

  @media screen and (max-width: 480px) {
    width: 110vw;
    height: 110vw;
    left: 0;
    position: relative;
  }

  @media screen and (max-width: 300px) {
    border-radius: 0;
    width: 100vw;
    padding: var(--padding);
  } ;
`;

const ProductTitle = styled.h1`
  font-weight: 700;
  font-size: var(--font-size-2);
  color: var(--blue-2);
  line-height: 1.4;
  text-transform: uppercase;
`;

const QuoteTitle = styled.h1`
  font-size: var(--font-size-2);
  color: white;
`;

const QuoteWrapper = styled.div`
  width: 35%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 60%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const SuggetsionContainer = styled.div`
  display: grid;
  width: var(--layout-desktop-container);
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 2fr;
  column-gap: var(--gap-3);

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  & svg {
    max-width: 100%;

    @media screen and (min-width: 681px) and (max-width: 860px) {
      max-width: 300px;
    }
  }
`;

const SuggestionTextContainer = styled.div`
  display: grid;
  row-gap: var(--gap-5);
`;

const SuggestionsParagraph = styled.p`
  font-size: var(--font-size);
  color: var(--foreground-white);
  line-height: 1.3;
`;

export const ProductCombo = ({
  relatedProductsArray,
  quoteTitle,
  quoteDescription,
  suggestionTitle,
  suggestionDescription,
  hasPaperBackground,
}) => {
  if (!relatedProductsArray) return null;

  const intl = useIntl();

  return (
    <Wrapper>
      <Container>
        {relatedProductsArray.length === 0 ? null : (
          <>
            <HeadingSection blueHeading>
              {intl.formatMessage({ id: 'kitchenPairings' })}
            </HeadingSection>
            <GridContainerLayout>
              <GridContainer>
                <Swiper
                  style={{
                    '--swiper-navigation-color': 'var(--gold-2)',
                    '--swiper-pagination-color': 'var(--gold-2)',
                  }}
                  grabCursor
                  pagination={{ clickable: true }}
                  slidesPerView={1}
                  spaceBetween={20}
                  breakpoints={{
                    1000: {
                      slidesPerView: relatedProductsArray.length === 1 ? 1 : 2,
                      spaceBetween: 30,
                    },
                  }}
                >
                  {relatedProductsArray.map(
                    ({
                      titolo,
                      sliderDescription,
                      id,
                      mainImage: { gatsbyImageData },
                      heroProductRedirect,
                      productHeroReference,
                    }) => (
                      <SwiperSlide key={titolo}>
                        <ProductCard>
                          <ProductImage image={gatsbyImageData} alt={titolo} />
                          <ProductTitle>{titolo}</ProductTitle>
                          <RegularParagraph>
                            {sliderDescription}
                          </RegularParagraph>
                          {heroProductRedirect === true ? (
                            <NavigatorButtonNormal
                              recordId={productHeroReference.originalId}
                              buttonText={intl.formatMessage({ id: 'discover' })}
                              $bolder
                            />
                          ) : (
                            <NavigatorButtonNormal
                              recordId={id}
                              buttonText={intl.formatMessage({ id: 'discover' })}
                              $bolder
                            />
                          )}
                        </ProductCard>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </GridContainer>
              <QuoteWrapper>
                <Quote hasPaperBackground={hasPaperBackground}>
                  <QuoteContainer>
                    <QuoteTitle>{quoteTitle}</QuoteTitle>
                    <SuggestionsParagraph>
                      {quoteDescription}
                    </SuggestionsParagraph>
                  </QuoteContainer>
                </Quote>
              </QuoteWrapper>
            </GridContainerLayout>
          </>
        )}
        <SuggetsionContainer>
          <ForksDrawing />
          <SuggestionTextContainer>
            <HeadingSection blueHeading>{suggestionTitle}</HeadingSection>
            <RegularParagraph css={{ maxWidth: '600px' }}>
              {suggestionDescription}
            </RegularParagraph>
          </SuggestionTextContainer>
        </SuggetsionContainer>
      </Container>
    </Wrapper>
  );
};
