import { graphql } from 'gatsby';
import { useEffect } from 'react';
import { useParallaxController } from 'react-scroll-parallax';

import { PageWrapper } from '../components/layout/PageWrapper';
import { ProductCombo } from '../components/layout/sections/hero-products/ProductCombo';
import { HeroProductHero } from '../components/layout/sections/hero-products/ProductSectionHero';
import { SectionQuoteBackground } from '../components/layout/sections/hero-products/SectionQuoteBackground';
import { SectionTwoThirdImage } from '../components/layout/sections/hero-products/SectionTwoThirdImage';
import { SectionWaveIllustration } from '../components/layout/sections/hero-products/SectionWaveIllustration';
import { SectionNutrition } from '../components/product/SectionNutritionTable';

const HeroProductTemplate = ({
  data: {
    datoCmsHeroProduct: {
      seo,
      productCategory: { categoryTitle, categorySlug, categoryRecordId },
      titolo,
      multilineDescription,
      mainImageHero: { mainImageHeroData },
      colorLayout,
      certificationImage,
      titleSectionOne,
      subtitleSectionOne,
      backgroundImageSectionOne: { backgroundImageSectionOneData },
      titleSectionTwo,
      subtitleSectionTwo,
      immagineRoundedSectionTwo: { roundedImageData },
      vectorSectionTwo,
      titleSectionThree,
      subtitleSectionThree,
      imageSectionThree: { sectionThreeImageData },
      titleSectionFour,
      subtitleSectionFour,
      backgroundImageSectionFour: { sectionFourImageData },
      valoriNutrizionali,
      prodottiCorrelati,
      quoteTitleSuggestions,
      quoteDescriptionSuggestions,
      suggestionTitleSuggestions,
      suggestionDescriptionSuggestions,
      altreImmaginiHero,
      limitedEdition,
    },
  },
  pageContext,
}) => {
  const parallaxController = useParallaxController();

  useEffect(() => {
    window.requestAnimationFrame(() => {
      parallaxController.update();
    });
  });

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <HeroProductHero
        limitedEdition={limitedEdition}
        certificationImage={certificationImage}
        otherImages={altreImmaginiHero}
        hasPaperBackground={colorLayout}
        categoryTitle={categoryTitle}
        categoryLink={categorySlug}
        imageData={mainImageHeroData}
        productTitle={titolo}
        productDescription={multilineDescription}
        categoryRecordId={categoryRecordId}
      />
      <SectionQuoteBackground
        hasOffset
        backgroundImageData={backgroundImageSectionOneData}
        sectionTitle={titleSectionOne}
        sectionDescription={subtitleSectionOne}
        hasPaperBackground={colorLayout}
      />
      <SectionWaveIllustration
        roundedImageData={roundedImageData}
        title={titleSectionTwo}
        subtitle={subtitleSectionTwo}
        hasPaperBackground={colorLayout}
        illustrationUrl={!vectorSectionTwo ? null : vectorSectionTwo}
      />
      <SectionTwoThirdImage
        title={titleSectionThree}
        subtitle={subtitleSectionThree}
        imageData={sectionThreeImageData}
      />
      <SectionQuoteBackground
        isLastSection
        hasPaperBackground={colorLayout}
        backgroundImageData={sectionFourImageData}
        sectionTitle={titleSectionFour}
        sectionDescription={subtitleSectionFour}
      />
      <SectionNutrition isHeroProduct nestedArray={valoriNutrizionali} />
      <ProductCombo
        hasPaperBackground={colorLayout}
        relatedProductsArray={prodottiCorrelati}
        quoteTitle={quoteTitleSuggestions}
        quoteDescription={quoteDescriptionSuggestions}
        suggestionTitle={suggestionTitleSuggestions}
        suggestionDescription={suggestionDescriptionSuggestions}
      />
    </PageWrapper>
  );
};

export default HeroProductTemplate;

export const query = graphql`
  query HeroProductQuery($id: String!, $locale: String!) {
    datoCmsHeroProduct(originalId: { eq: $id }, locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      mainImageHero {
        mainImageHeroData: gatsbyImageData(placeholder: NONE, aspectRatio: 1)
      }
      colorLayout
      originalId
      titolo
      multilineDescription
      titleSectionOne
      subtitleSectionOne
      backgroundImageSectionOne {
        backgroundImageSectionOneData: gatsbyImageData
      }
      titleSectionTwo
      subtitleSectionTwo
      immagineRoundedSectionTwo {
        roundedImageData: gatsbyImageData
      }
      altreImmaginiHero {
        otherImagesData: gatsbyImageData(placeholder: NONE, aspectRatio: 1)
      }
      vectorSectionTwo {
        illustrationUrl: url
      }
      titleSectionThree
      subtitleSectionThree
      imageSectionThree {
        sectionThreeImageData: gatsbyImageData
      }
      titleSectionFour
      subtitleSectionFour
      backgroundImageSectionFour {
        sectionFourImageData: gatsbyImageData
      }
      certificationImage {
        certificationImageData: gatsbyImageData(layout: FIXED)
        certificationImageUrl: url
        format
      }
      productCategory {
        categoryRecordId: originalId
        categoryTitle: title
        categorySlug: slug
      }
      mainImage {
        gatsbyImageData
      }
      valoriNutrizionali {
        originalId
        tipo
        informationNode {
          childMarkdownRemark {
            html
          }
        }
        quantitValoriMedi
        valoriNutrizionaliNested {
          value
          name
          originalId
        }
      }
      limitedEdition
      quoteTitleSuggestions
      quoteDescriptionSuggestions
      suggestionTitleSuggestions
      suggestionDescriptionSuggestions
      prodottiCorrelati {
        ... on DatoCmsHeroProduct {
          id: originalId
          slug
          sliderDescription
          mainImage {
            gatsbyImageData
          }
          titolo
          productCategory {
            categorySlug: slug
          }
        }
        ... on DatoCmsProdotti {
          id: originalId
          slug
          sliderDescription
          mainImage {
            gatsbyImageData
          }
          titolo
          productCategory {
            categorySlug: slug
          }
          heroProductRedirect
          productHeroReference {
            originalId
            productHeroSlug: slug
            productCategory {
              productHeroCategorySlug: slug
            }
          }
        }
      }
    }
  }
`;
