/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIntl } from 'react-intl';
import { Breadcrumb } from '../../../product/BreadCrumb';
import { FacebookIcon } from '../../../vectors/Social';
import { HeadingSection, SubtitleSection } from '../../../styles/Typography';
import { SvgWaveBottomStaticFake } from '../../../vectors/Waves';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { LimitedEdition } from '../../../misc/LimitedEdition';

SwiperCore.use([Pagination]);

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding: var(--padding);
  background: ${({ hasPaperBackground }) =>
    hasPaperBackground === false &&
    'url(https://www.datocms-assets.com/56612/1641694573-paper-pattern.jpg)'};
`;

const Container = styled.div`
  width: 1280px;
  display: flex;
  gap: var(--gap-2);
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 810px) {
    flex-direction: column;
    gap: 0;
  }
`;

const Left = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 810px) {
    margin-bottom: var(--gap-5);
    width: 100%;
  }
`;

const ProductImage = styled(GatsbyImage)`
  display: flex;
  max-width: 550px;
`;

const Right = styled.div`
  align-items: center;
  flex-direction: column;
  display: grid;
  row-gap: var(--gap-5);
  height: min-content;
  justify-content: center;
  align-items: flex-start;
`;

const ShareSpan = styled.span`
  font-size: var(--font-size);
  color: var(--gold-2);
  white-space: nowrap;
  font-weight: 500;
`;

const SocialIcons = styled.div`
  display: grid;
  margin-top: var(--gap-5);
  align-items: center;
  column-gap: var(--gap-5);
  grid-auto-flow: column;
  width: min-content;

  & svg {
    width: 30px;
    height: 30px;
    & path {
      fill: var(--gold-2);
    }
  }
`;

export const HeroProductHero = ({
  imageData,
  productTitle,
  productLink,
  productDescription,
  categoryTitle,
  categoryLink,
  hasPaperBackground,
  format,
  certificationImage,
  otherImages,
  limitedEdition,
  categoryRecordId,
}) => {
  const { pathname } = useLocation();
  const intl = useIntl();

  return (
    <>
      <Breadcrumb
        hasPaperBackground={hasPaperBackground}
        categoryTitle={categoryTitle}
        categoryRecordId={categoryRecordId}
        categoryLink={categoryLink}
        productTitle={productTitle}
        productLink={productLink}
      />
      <Wrapper hasPaperBackground={hasPaperBackground}>
        <Container>
          <Left>
            {!otherImages ? (
              <ProductImage image={imageData} alt={productTitle} />
            ) : (
              <Swiper
                style={{
                  '--swiper-navigation-color': 'var(--gold-2)',
                  '--swiper-pagination-color': 'var(--gold-2)',
                  '--swiper-pagination-bullet-width': '12px',
                  '--swiper-pagination-bullet-size': '12px',
                }}
                grabCursor
                pagination={{ clickable: true }}
                slidesPerView={1}
                spaceBetween={40}
              >
                <SwiperSlide>
                  <ProductImage image={imageData} alt={productTitle} />
                </SwiperSlide>
                {otherImages.map(({ otherImagesData }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={`otherProduct_${index}`}>
                    <ProductImage
                      image={otherImagesData}
                      alt={productTitle}
                      loading="eager"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Left>
          <Right>
            {limitedEdition && (
              <LimitedEdition
                content={intl.formatMessage({ id: 'limitedEdition' })}
              />
            )}
            <HeadingSection goldHeading>{productTitle}</HeadingSection>
            <SubtitleSection>{productDescription}</SubtitleSection>
            <SocialIcons>
              <ShareSpan>{intl.formatMessage({ id: 'share' })}</ShareSpan>
              <a
                rel="noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=https://larosadeigusti.it${pathname}`}
              >
                <FacebookIcon />
              </a>
            </SocialIcons>

            {!certificationImage ? null : format === 'jpg' ||
              format === 'png' ? (
              // eslint-disable-next-line react/jsx-indent
              <GatsbyImage
                image={certificationImage.certificationImageData}
                alt={productTitle}
              />
            ) : (
              <img
                src={certificationImage.certificationImageUrl}
                alt={productTitle}
              />
            )}
          </Right>
        </Container>
      </Wrapper>
      <SvgWaveBottomStaticFake hasPaperBackground={hasPaperBackground} />
    </>
  );
};
