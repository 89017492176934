import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ hasOffset }) => hasOffset && '-85px'};
  overflow: hidden;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

const SectionBackground = styled(GatsbyImage)`
  width: 100%;
  height: 700px;

  @media screen and (max-width: 768px) {
    height: 580px;
  }

  @media screen and (max-width: 480px) {
    height: 450px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  position: absolute;
  z-index: 1;
  align-items: flex-end;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: var(--padding);
  }

  @media screen and (max-width: 680px) {
    position: relative;
    align-items: unset;
    padding: 0;
  }
`;

const TextBoxWrapper = styled.div`
  display: flex;
  position: relative;
  width: min-content;

  @media screen and (max-width: 680px) {
    width: unset;
  }
`;

const roundedTextBoxShared = css`
  display: grid;
  row-gap: var(--gap-6);
  width: 500px;
  height: 500px;
  border-radius: 100vw;
  justify-items: start;
  align-content: center;
  padding: 80px;
  position: relative;
  left: -8%;

  @media screen and (max-width: 680px) {
    width: 110vw;
    height: unset;
    margin-top: 0;
    left: 0;
    border-radius: 0;
    background: white;
    padding: 40px 20px;
  }

  @media screen and (max-width: 360px) {
    border-radius: 0;
    width: 100vw;
    height: 100%;
    padding: 20px;
  }
`;

const RoundedTextBox = styled.div`
  ${roundedTextBoxShared};
  background: ${({ hasPaperBackground }) =>
    hasPaperBackground === false ? 'var(--gold-2)' : 'var(--blue-2)'};
  color: white;

  @media screen and (max-width: 680px) {
    color: var(--blue-2);
  }
`;

const RoundedTextBoxLast = styled.div`
  ${roundedTextBoxShared}
  background: white;
  color: var(--blue-2);
`;

const HeadingSection = styled.h1`
  font-weight: 700;
  line-height: 1.3;
  z-index: 1;
  max-width: 500px;

  @media screen and (max-width: 680px) {
    font-size: var(--font-size-4);
  }
`;

export const SubtitleSection = styled.p`
  font-size: var(--font-size-2);
  font-weight: 500;
  line-height: 1.6;
  max-width: 500px;

  @media screen and (max-width: 680px) {
    font-size: ${({ noMobileResize }) =>
      noMobileResize ? 'var(--font-size-2)' : 'var(--font-size)'};
  }
`;

export const SectionQuoteBackground = ({
  backgroundImageData,
  sectionTitle,
  sectionDescription,
  hasPaperBackground,
  hasOffset,
  isLastSection,
}) => (
  <Wrapper hasOffset={hasOffset}>
    <SectionBackground image={backgroundImageData} alt="" />
    <Container>
      <TextBoxWrapper>
        {isLastSection ? (
          <RoundedTextBoxLast hasPaperBackground={hasPaperBackground}>
            <HeadingSection>{sectionTitle}</HeadingSection>
            <SubtitleSection>{sectionDescription}</SubtitleSection>
          </RoundedTextBoxLast>
        ) : (
          <RoundedTextBox hasPaperBackground={hasPaperBackground}>
            <HeadingSection>{sectionTitle}</HeadingSection>
            <SubtitleSection>{sectionDescription}</SubtitleSection>
          </RoundedTextBox>
        )}
      </TextBoxWrapper>
    </Container>
  </Wrapper>
);
