import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { HeadingSection, SubtitleSection } from '../../../styles/Typography';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--gap-2) var(--padding);
  align-items: center;
`;

const Container = styled.div`
  width: var(--layout-desktop-container);
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: var(--gap-4);
  align-items: center;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    row-gap: var(--gap-4);
  }
`;

const Image = styled(GatsbyImage)``;

const TextContainer = styled.div`
  display: grid;
  row-gap: var(--gap-6);
  height: min-content;
`;

export const SectionTwoThirdImage = ({ title, subtitle, imageData }) => (
  <Wrapper>
    <Container>
      <TextContainer>
        <HeadingSection blueHeading>{title}</HeadingSection>
        <SubtitleSection>{subtitle}</SubtitleSection>
      </TextContainer>
      <Image image={imageData} alt={title} />
    </Container>
  </Wrapper>
);
